.edit-groups{
    margin-top: 20px;
}

#back-user-list{
    margin-top: 35px;
    margin-left: 45%;
}

#edit-user-groups{
    margin-top: 35px;
    float: right;
}

#button-div{
    width: auto;
    height: 80px;
}

.editUser{
    width: 50%;
}