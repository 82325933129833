.add-user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.11em;
}
.generic-flex-display {
  display: flex;
}
.refresh-container {
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
}
.refresh-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-bar {
  width: 14em;
  padding-top: 0.2em;
  padding-left: 1.1em;
}
.table-top {
  display: flex;
  padding-top: 1em;
  padding-left: 1rem;
  padding-right: 1rem;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #737578;
}

.MuiTablePagination-caption{
  display: none;
}