.eula-screen {
  background-color: #1b3e77;
  height: 100vh;
}

.eulaComponent {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  color: #1b3e77;
  margin: 0 auto !important;
  font-size: 25px;
  width: 65vw;
  padding: 50px;
  z-index: 1;
}

.titleText {
  padding-left: 5px;
  padding-bottom: 1vw;
  font-family: 'Roboto';
  font-size: 24pt;
  font-weight: 300;
}

.idCheck {
  color: #1b3e77 !important;
}

.eulaBox {
  border: 3px solid #dbdbdb;
  font-family: 'Roboto';
  height: 325px;
  padding: 15px 30px;
  margin-bottom: 3vh;

  span {
    color: #1b3e77;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;

  label.checkBox span:nth-child(2) {
    color: #1b3e77;
    font-size: 18px;
    width: 30vw;
  }
}

.download-accept-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

#eulaAcceptButton:disabled {
  opacity: 0.5;
}

.eula-pdf {
  font-family: 'Roboto';
  color: #1b3e77;
  font-size: 14pt;
  text-decoration: underline;
  cursor: pointer;
}
