.appselect {
  display: inline;
  margin: 5px;
  p {
    display: block;
    height: flex;
  }
  &:hover {
    background: rgba(82, 110, 130, 0.461);
  }
}
