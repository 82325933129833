#authLayout {
  display: flex;
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  justify-content: center;

  #odysseyLogo {
    width: 216.5px;
    height: 76.5px;
    background-image: url(../../images/logo_odyssey.png);
    background-size: contain;
    background-repeat: no-repeat;
  }

  #loginImg {
    background-image: url(../../images/login_img.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  #googLogo {
    background-image: url(../../images/logo_google.svg);
    justify-content: center;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat; 
  }

  #oneLoginLogo {
    background-image: url(../../images/oneloginlogo.png);
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    border-color: black;
    border-width: 1px;
  }

  #loginBackground {
    margin: -10% 6% 6% 8%;
    height: auto;
    max-height: 80vw;
    flex-grow: 5;
    background-color: #6d9eeb;
    width: 50vw;

    .login-image {
      padding-top: 24%;
      padding-bottom: 7%;
      float: right;
      width: 105%;
      height: auto;
      margin-right: -12.7%;
    }
  }
}

.op-button-text-fix {
  border-radius: 0 !important;
  .buttonText {
    padding: 0;
    float: none;
    text-align: center;
    font-family: Roboto !important;
  }
}

@media (max-width: 600px) {
  #authLayout {
    #loginBackground {
      display: none;
    }
  }
}

