//override amplify react toast close button
a[class*='toastClose'] {
  width: 16px;
  height: 16px;
  &:before,
  &:after {
    background: rgb(97, 26, 21);
  }

  &:hover {
    &:before,
    &:after {
      background: rgb(97, 26, 21);
    }
  }
}

.authForm {
  color: #1155cc;
  font-size: 1.2em;
  margin-left: 11%;
  margin-right: 3%;
  margin-top: 3%;
  width: 275px;
  height: auto;
  flex-grow: 1;

  .op-logincomponent-label {
    padding-bottom: 0.75vw;
    // font-size: 1.7vw;
    font-weight: 300;
  }

  .op-logincomponent-input {
    padding-bottom: 1vw;
    height: auto;

    input {
      padding: 20px 10px;
      background-color: #ffffff;
      width: 100%;
      border-radius: 6px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 6px;
    }
  }

  .cat-forgot-password {
    padding-top: 0.5vw;
    cursor: pointer;
  }

  .catButton {
    height: 75px;
    width: 275px;
  }

  .op-login-test {
    color: #1155cc;
    font-family: 'Roboto';
  }

  .op-bottom-login-text {
    text-decoration: underline;
    height: auto;
  }

  .op-assistance-text {
    margin-top: 8%;
    width: 100%;
    height: auto;
  }

  .op-assistance-text a {
    color: #1155cc;
    text-decoration: underline !important;
    width: 100%;
    height: auto;
  }

  #loginButton:disabled {
    opacity: 0.5;
  }

  .op-logincomponent-backlink {
    display: block;
    cursor: pointer;
    font-size: 0.8em;
    margin-top: 20px;
  }
}

.op-button-text-fix {
  border-radius: 0 !important;
  .buttonText {
    padding: 0;
    float: none;
    text-align: center;
    font-family: Roboto !important;
  }
}

@media screen and (max-width: 812px) {
  .authForm {
    margin-top: 50px;

    .op-logincomponent-label {
      padding-bottom: 0.75vw;
      font-size: 3vw;
      font-weight: 300;
    }

    .op-logincomponent-label {
      input {
        font-size: 3vw;
        width: 44vw;
      }
    }

    .op-logincomponent-input {
      button {
        margin-top: 5%;
        border-radius: 6px;

        .buttonText {
          height: auto;
          font-size: 3.5vw !important;
          padding: 10px;
          padding-right: 0;
        }

        .arrowIcon {
          padding-top: 5%;
          padding-right: 20px;

          img {
            width: 16px;
            height: 10px;
          }
        }
      }
    }

    .op-login-test {
      font-size: 3vw;
    }

    .op-assistance-text {
      margin-top: 2%;
    }
  }

  #loginBackground {
    display: none;
  }
}
