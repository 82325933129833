body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
}

#header {
  #navbar-button-box {
    display: none;
  }
}

.admin-ui-container {
  width: 75%;
  margin: 50px auto;
  padding: 15px 50px 50px;

  &.add-user {
    width: 60%;
  }

  &.edit-user {
    width: 50%;
  }
}
